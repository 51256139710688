import 'bootstrap/dist/css/bootstrap.css';
import RecordView from '../recorder/recorder'
import './nomagazine.css';
import 'bootstrap/dist/css/bootstrap.css';
import React,{useState,useEffect} from 'react';
import '../form-user/info.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { useJsApiLoader } from '@react-google-maps/api';
import { IoLocation } from "react-icons/io5";
import CurrentLocation from './CurrentLocation';
import {FaRegUser} from "react-icons/fa";
import uploadFileToBlob, { isStorageConfigured } from '../recorder/azureBlob';
import {useRef} from 'react';
import { FiSend } from "react-icons/fi";
import axios from "axios";
import swal from 'sweetalert';
import { useMediaQuery } from 'react-responsive';
import Swal from "sweetalert2";
import Footer from '../footer/footer';
import $ from 'jquery';



const onError = (type, status) => console.log(type, status)

 function Nomagazine(props){
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const[showagain,setshowagain]=useState(false)


  const storageConfigured = isStorageConfigured();
  //to get current location 
  const [CurrentLoc, setCurrentLoc] = useState('');
  const handleResults = (results) => {
    setCurrentLoc(results.filter((item) => {
      return (item.types.indexOf('street_address') >= 0);
    })[0].formatted_address)
   }
  // to put current location in input 
  const InputChange= (Value) => setCurrentLoc(Value);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyASbQBHuvWt93PcUlu1F2HAjmrboNmNS2Q"
  })
  //to put input value 
  
  // object that has details of order and details of user form 
  const dataform={
   consumer:{ 
    phone:'',
    name:'',
    address:'',
    email:'',
    paymentMethod:'',
    record:"",
    deliveryOrder:1
   },
   orderItemLst:[{
    detOrder:props.detOrder
  
   }],
   merchantMagazineId:''
   
  //  dateofdeliverd:'',
    // comment:''
  };
  // blob of record 
  // const[mediaBlobUrl,setmediaBlobUrl]=useState('')
  // to declare value of input form 
  const[phone,setphone]=useState()
  const[name,setname]=useState()
  const[email,setemail]=useState()
  const[dateofdeliverd,setdatedeliv]=useState()
  const[paymentMethod,setpaymentMethod]=useState()
  //to set value of input 
  const handleChangephone = event => {
    setphone (event.target.value);
  
  };
  
  // console.log(props.comment)
  const handleChangename = event => {
    setname (event.target.value);
  
  };
  
  const handleChangeemail = event => {
    setemail (event.target.value);  
  };
  
  const handleChangepaymentMethod = event => {
    setpaymentMethod (event.target.value);
  };
  
  const handleChangedate = event =>{
    setdatedeliv(event.target.value)
  };
  
  const [comment, setcomment] = useState('');
  const handleChangecomment = event => {
    
    setcomment(event.target.value)
    // console.log('value is:', event.target.value);
  };
  


  //function of submit (set value of form and audio and order )
  const handleItemSubmit = (event) => {
    event.preventDefault();
    // console.log(props.comment)
    dataform.consumer.phone=phone 
    dataform.consumer.name=name
    dataform.consumer.email=email  
    dataform.consumer.address=CurrentLoc
    dataform.consumer.comment=props.CommentNomagazine
    dataform.merchantMagazineId=props.merchantMagazineId
    dataform.consumer.record=props.Recfile
    dataform.consumer.message=message
    if(dataform.consumer.address=='' || dataform.consumer.address==null)
  {
    swal({
     
      text: "يجب أدخال العنوان",
      buttons: true,
      dangerMode: true,
      
  
    })
  }  

  else if(!dataform.consumer.phone.match(/^\d+$/))
  {
    
    // console.log("type",typeof(dataform.consumer.phone))
  
    Swal.fire("تاكد من التليفون ")
  
  
  }

  else if(dataform.consumer.email=='' || dataform.consumer.email==null)
  {
    swal({
     
      text: "يجب أدخال الأيميل",
      buttons: true,
      dangerMode: true,
      
  
    })
  }
  else if(dataform.consumer.name=='' || dataform.consumer.name==null)
  {
    swal({
     
      text: "يجب أدخال الأسم",
      buttons: true,
      dangerMode: true,
      
  
    })
  }

  else if(dataform.consumer.phone=='' || dataform.consumer.phone==null )
  {
    swal({
     
      text: "يجب أدخال التليفون ",
      buttons: true,
      dangerMode: true,
      
  
    })
    
  }
  else if(dataform.consumer.comment== undefined && dataform.consumer.record==undefined){

    Swal.fire("من فضلك ارسل رساله صوتيه او ملاحظات علي الاقل   ")
  
  
  }
 
  else{
  
    onFileUpload()
    lsRememberMe()
  //  createPost()
    importFile()
  }
  
  
  }
  const importFile= async (e) => {
    const formData = new FormData();
    formData.append("order", JSON.stringify(dataform));
    formData.append("file", dataform.consumer.record);
    try {
      const res = await axios.post("http://tijaryservice.tijary.store/SubmitOrderNoMagazine", formData)
      .then((resp) =>{   
        Swal.fire({
          title: 'تم الأرسال',
          icon: 'success',
          timer: 2000,
          confirmButtonText: 'تم'
      })
             window.location.reload()});

  
    } catch (ex) {
      console.log(ex);
    }
  };
  //////////////////////handle button of time delivery///////////////////
               const [message, setMessage] = useState('');
               // btn of 2 hours
              const handlebchangetobtn2= event =>{
                event.preventDefault();
                    setMessage(1);
                    // console.log(message)
                }
                // btn of 3 hours
                const handlebchangetobtn3= event =>{
                  event.preventDefault();
                      setMessage(2);
                  }
                  // btn of 4 hours
                  const handlebchangetobtn4= event =>{
                    event.preventDefault();
                        setMessage(3);
                    }
                     ///// btn  oof now 
                    const handlebchangetobtnnow= event =>{
                      event.preventDefault();
                          setMessage(4);
                      }
                      //btn  tommorow morning
                      const handlebchangebtntomAm= event =>{
                        event.preventDefault();
                            setMessage(5);
                        }
                        // btn tommorow evening 
                        const handlebchangebtntommidAm= event =>{
                          event.preventDefault();
                              setMessage(6);
                          }
                          //btn tommorow night
                          const handlebchangebtntompm= event =>{
                            event.preventDefault();
                                setMessage(7);
                            }
  
    
  /////////////////////upload voice//////////////
  const [blobList, setBlobList] = useState([]);
    // current file to upload into container
  const [uploading, setUploading] = useState(false);
    const [inputKey, setInputKey] = useState(Math.random().toString(36).split());
    const onFileUpload = async () => {
      // prepare UI
      setUploading(true);
      // *** UPLOAD TO AZURE STORAGE ***
      const blobsInContainer = await uploadFileToBlob(props.flieup);
      // prepare UI for results
      setBlobList(blobsInContainer);
      // reset state/form
      setUploading(false);
      setInputKey(Math.random().toString(36));
       }
       ////////////////////remember me //////////////////////////////
  // to use ref in remember me checked
   const ref = useRef(null)
  
  // function remember me 
  function lsRememberMe(){
    if (ref.current.checked){
      
      localStorage.setItem('email',email)
      localStorage.setItem('name',name)
      localStorage.setItem('phone',phone)
      localStorage.setItem('address',CurrentLoc) 
      localStorage.setItem('rememberMe',ref.current.checked) 
     // localStorage.setItem('rememberMe',ref) 
       
    }
    else{
      localStorage.clear()
    }
    
  }
  // const merchantMagazineId = 11

     
  const fetchIdmagazine = (merchantId) => {
    console.log("fetchIdmagazine",merchantId)
    return axios.get("http://tijaryservice.tijary.store/GetLastMagazineIDwithMagazineMerhanctID/"+merchantId)
          .then((response) => {
           fetchHeader(response.data.magazineID);
            });
  }
  const [header, setHeader] = useState();
  const [footer, setfooter] = useState();
  
  const fetchHeader = (magazineID) => {
    
    return axios.get('http://tijaryservice.tijary.store/api/MagazineHead/GetDefaultMerchantMagazine/'+magazineID)
          .then((response) => {
            setHeader(response.data.defaultHeaders);
            setfooter(response.data.footer);
            console.log("response.data.defaultHeaders",response.data)
          })
         
  }
  const [url,seturl]=useState(window.location.href)
  ///////////////////////////////////////// to put data when reload page ///////////////
  useEffect(() => {
    setphone(localStorage.getItem('phone'));
    setname(localStorage.getItem('name'));
  setCurrentLoc(localStorage.getItem('address'));
  setemail(localStorage.getItem('email'));
  if(localStorage.getItem('phone')!=''){
    ref.current.checked = true;
  
  }
  fetchIdmagazine(props.merchantMagazineId);
  }, []);
  
  function createPost() {
  
    const options = {
      method: 'post',
      url: 'http://172.16.0.249:5555/SubmitOrder',
      data: dataform,
      transformResponse: [(data) => {
        // console.log(data);
        return data;
      }]
    };
    // send the request
    axios(options);
  }
  const showprint=event=>{
    event.preventDefault()
    
    props.handleprint(true)
      // console.log("props.handleprint",props.handleprint)
    
    
    }
    function paymentmethod(Id){
      const AllPayBtn=document.getElementsByClassName('paymentbutton')
      const SelectedpayBtn =document.getElementById('paymentmethod'+Id)
      for (var i = 0; i < AllPayBtn.length; i++) {
        AllPayBtn[i].style.backgroundColor="white";
    }
      SelectedpayBtn.style.background='#696969'
    }
    const videoload = event =>{
      Swal.fire({
        // icon: 'info',
        html:
          '<iframe class="iframwidth" height="300" src="https://www.youtube.com/embed/5KtYqJwpgRg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
           +'<label class="fontmaaray">عدم اظهار الفيديو مره اخري</label>'
           +'<input type="checkbox" id="cheackinvideo" >',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
          'موافق',
        confirmButtonAriaLabel: 'Thumbs up, great!',
    
      }).then((result) => {
    const x=document.getElementById("cheackinvideo").checked
    const Elecheck=document.getElementById("cheackinvideo")
        if (result.isConfirmed && x === true) {
          setshowagain(true)
          localStorage.setItem('showagain', true)
          Elecheck.setAttribute("checked","")
          
    
        }
       
      else{
        localStorage.setItem('showagain', false)
    
    
      }
      
      })
    
      var cheackinvideo = document.getElementById("cheackinvideo");
      var showagain = localStorage.getItem('showagain')
    
      if(showagain == "true")
         cheackinvideo.setAttribute("checked",showagain);
      else
         cheackinvideo.removeAttribute("checked");
         localStorage.setItem('showagain', false)
    
    }
    const openModalofshare = () => {
      $('.modalshare').removeClass("d-none");
      $('.modalshare').addClass("d-block");
      $('body').addClass("disable-scroll");
      $('html, body').css({
        overflow: 'hidden',
        height: '100%'
    });
      
    };
    
    const closeModalofshare = () => {
      $('.modalshare').removeClass("d-block");
      $('.modalshare').addClass("d-none");
      $('body').removeClass("disable-scroll");
    };
    const openModal = () => {
      $('.modal-yasser').removeClass("d-none");
      $('.modal-yasser').addClass("d-block");
      $('body').addClass("disable-scroll");
    };
    
    const closeModal = () => {
      $('.modal-yasser').removeClass("d-block");
      $('.modal-yasser').addClass("d-none");
      $('body').removeClass("disable-scroll");
    };
    const[Recfile,setRecfile]=useState(props.Recfile)
    const[CommentNomagazine,setCommentNomagazine]=useState(props.CommentNomagazine)

    return (
        <div>
    {header &&  <div >         
     <div > 
     <div className="row justify-content-center">
      
      <div className="col-3 mt-2">
        
      <img src={header.headerLogo}  class="logo-imagesNomazine"></img>
      </div>
      <div className="col-9 mt-2">
        <div className="row justify-content-center headinmobile" >
      <h3 className="text-header card-title magazine-title " style={{"color":header.headerFontColor}}>{header.headerTitle}</h3>
    </div>
    <div className="row justify-content-center">
      <h6 className="card-title  magazine-title  " style={{"color":header.headerSubtitleColor , height:"20px !important"}} >{header.subtitle}</h6>
      </div>
    </div>
      </div>      
     </div>
     </div>}

<div className='mt-4'>
    <h1 className='fontmaaray'> ترقبو قائمه الأسعار الجديده  </h1>
</div>
<div className='mt-4'>
<RecordView 
Recfile={Recfile}
setRecfile={props.setRecfile}

CommentNomagazine={CommentNomagazine}
setCommentNomagazine={props.setCommentNomagazine}

></RecordView>
</div>
<div className='container-fluid fontmaaray mt-3'>
  

        <div className='row justify-content-center'> 
    <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
  
         <div className='container-fluid bord'>
         <div className='row justify-content-center'id='maindiv'> 
    <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
  
         <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <FaRegUser  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center mt-1'>بيانات الشخصية</h1> 
         

         
            </div>
    </div>
    <form className='container-fluid' /*</div>onSubmit={handleSubmit}*/>
    <div className='row'> 
    <div>
      
  {/*  <div class="form-group">
    <input
          id="first_name"
          name="first_name"
          type="text"
          onChange={event => setFirstName(event.target.value)}
          value={firstName}
        />
        <input
          id="last_name"
          name="last_name"
          type="text"
          value={lastName}
          onChange={event => setLastName(event.target.value)}
        />

</div>*/}

         <div className="form-group">
    <input type="text" className="form-control mt-3 " style={{'font-size': 'x-large'}}  id='phone'   name='phone' value={phone} onChange={handleChangephone}   placeholder="رقم موبيل " ></input>
  </div>
  <div className="form-group">
    
    <input type="text" className="form-control mt-2 "style={{'font-size': 'x-large'}} placeholder="الاسم" id='name' value={name}  name='name' onChange={handleChangename}></input>
    {/* <input type="text" className=" inputshow" value={comment} name='comment'  onChange={handleChangecomment}></input> */}
    <input
    className='inputshow'
        type="text"
        id="comment"
        name="comment"
        onChange={handleChangecomment}
        value={comment}
      />

  </div>
  <div className="form-group ">
  <CurrentLocation onFetchAddress={handleResults} onError={onError}>
      {({ getCurrentLocation, loading }) => (
           <div className='row mt-2'> 
  
         <input
         onChange={evt => InputChange(evt.target.value)} 
         
         className="mt-2 col-lg-11 fs-5  form-control text-area-width " type='text'  placeholder="العنوان" 
        value={CurrentLoc}  name='CurrentLoc' id='CurrentLoc'></input>
        <button if onClick={getCurrentLocation} disabled={loading} className='btn btn-warning col-sm-1 btnwidth'>
          <div className='text-white'>
          <IoLocation className='fs-5 text-center '></IoLocation>
          <p>مكاني</p>
          </div> 
        </button>
        </div>
        

      )}
    </CurrentLocation>
  
  {/* <CurrentLocation onFetchAddress={handleResults} onError={onError}>
      {({ getCurrentLocation, loading }) => (
           <div className='row mt-2'> 
  
         <input
         onChangoe={evt => InputChange(evt.target.value) } 
         className="col-lg-11   form-control text-area-width" style={{'width': '80%','margin-right': '1%' ,'font-size': 'x-large'}} type='text'  placeholder="العنوان" 
        value={CurrentLoc}  name='CurrentLoc' id='CurrentLoc'></input>

        <button if onClick={getCurrentLocation}  disabled={loading} style={{'width':'17%','margin-right':'1%','margin-top':'0px' }} className='btn btn-warning col-sm-1 btnwidth '>
          <div className='text-white mt-1'>
          <IoLocation className='text-center fs-2 ' style={{'width':'100%'}}></IoLocation>
           <p className=' fs-4'>مكاني</p>
          </div> 
        </button>
        </div>
        

      )}
    </CurrentLocation> */}

 
  </div>
  <div className="form-group">
    
    <input type="email" className="form-control mt-2 " style={{'font-size': 'x-large'}} placeholder="الايميل" onChange={handleChangeemail} value={email} id='email' name='email'></input>
  </div>
  <div className='row mt-3'>
  
 
  {isDesktopOrLaptop &&
  <div className='row'>
    <div className='col-2'> <input type='checkbox' style={{'width':'100%','height':'100%','margin-right': '-120px'}} ref={ref} id="rememberMe"></input>
  </div>
   <div className=' col-6'>
   <label for='rememberMe' name="rememberMe"> <span  className='fs-5 text-success' style={{'margin-right': '-306px'}}  >  الاحتفاظ بالبيانات الشخصيه لاستخدمها في المره القادمه</span></label>
   </div>
   </div>
   }
   {isTabletOrMobile &&
   <div className='row'>
     <div className='col-2'> <input type='checkbox' style={{'width':'100%','height':'100%'}} ref={ref} id="rememberMe"></input>
  </div>
    <div className='col-9'>
   <label for='rememberMe' name="rememberMe"> <span  className='fs-5 text-success'  >  الاحتفاظ بالبيانات الشخصيه لاستخدمها في المره القادمه</span></label>
   </div>
   </div> }
   

  </div>

  {/* <div className='row  justify-content-center mt-2 '>
  <input value={TimeDelivery}  onChange={handleChangedate} id='date' name='date' className='hidedeliveredinput'></input>
    <button style={{'width':'45%' ,'height':'10%','margin-left':'4%','background':'red','font-weight': 'bold'}} className=' btn text-white ' id='delived' type="button" onClick={handlebchangetobtnnow} >توصيل </button>
    <button  style={{'width':'45%','margin-left':'2%','background':'#696969'}} onClick={handlebchangetobtn2} id='hand' className='btn text-white' type="button" value='1'>إستلام </button>

    </div> */}
  <hr></hr>
{/* 
  <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <GiDeliveryDrone  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center'>ميعاد التوصيل  </h1> 
         

         
            </div>
    </div>
  
  <div className='row mt-3'>



<div id='displaybtn' className='container-fluid'>

    <div className='row justify-content-center mt-2 '>
  <button style={{'width':'94%','margin-left':'2%','background':'red','font-weight': 'bold' }} className='btn  btn-white text-white' id='now' type="button" onClick={handlebchangetobtn3}>الأن</button>
</div>
<div className='row justify-content-center  mt-2' >
<button style={{'width':'22%','margin-left':'2%','background':'#696969'}}  className='btn btn-white text-white'  id='after2' type="button" onClick={handlebchangetobtn4}>بعد 2 ساعه  </button>

    <button style={{'width':'23%','margin-left':'2%','background':'#696969'}} className='btn btn-white text-white'id='tomM' type="button" onClick={handlebchangebtntomAm} >غداً&nbsp;&nbsp; ص . </button>
  <button  style={{'width':'22%','margin-left':'2%','background':'#696969'}} className='btn btn-white text-white' id='tomE' type="button" onClick={handlebchangebtntommidAm}>غداً&nbsp;&nbsp;ظ. </button>
  <button style={{'width':'22%','margin-left':'2%','background':'#696969'}} className='btn btn-white text-white' id='tomN' type="button" onClick={handlebchangebtntompm}>غداً&nbsp; &nbsp;م. </button>
</div>
</div>

</div> */}
{/* <hr className='mt-4' ></hr> */}

{/* <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <MdPayment  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center'>طريقه السداد</h1> 
         

         
            </div>
    </div> */}



<div className='row'>

<div className='col-12'>
<button  className="btn btn-success w-100 mt-3 fs-1 text-white  submitheight" onClick={handleItemSubmit}><FiSend className='fs-1 mx-4 mt-2'>
  
  </FiSend>
  ارسل  </button>
</div>


 
 
    </div>
  </div>
  </div>
 
</form>

</div>

</div>
    </div>


</div>

</div>

</div>
{footer &&
<div id="footer">
<Footer footer={footer}/>
</div>
}



        </div>
    )
}
export default Nomagazine